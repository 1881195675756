import { Row, Col } from 'antd';
import buildPicker from './pickerBuilder';
import Icon from '../Icon.jsx';
import { getAllBundlesApi } from '@/src/modules/device-automation/bundles.js';
import PackageLogo from '@/src/modules/device-automation/components/PackageLogo';
import Picker from './Picker';

const { Provider, contextHook } = buildPicker(
  (...args) => getAllBundlesApi(...args, { category: 'compliances' }),
  ComplianceBundlePicker,
  (i, options) => ({
    value: i.id,
    os: i.os,
    filterLabel: `${i.name} - ${i.description}`,
    label: (
      <Row gutter={16} wrap={false} align="middle">
        <Col flex="none" className="items-center inline-flex">
          <Icon name={`platform_${i.os}`} className="text-base" />
        </Col>
        <Col flex="auto" className="items-center inline-flex">
          <PackageLogo disabled package={i} style={{ width: '20px', marginRight: '0.5rem' }} />
          {i.name}
        </Col>
      </Row>
    )
  })
);

function ComplianceBundlePicker({ target, ...props }) {
  const { filteredOptions, allOptions, search } = contextHook();
  let options = filteredOptions || allOptions;
  if (target === 4) {
    options = Array.isArray(options)
      ? options
      : Array.from(options.values()).filter((i) => i.os.toLowerCase() === 'windows');
  }
  if (target === 5) {
    options = Array.isArray(options)
      ? options
      : Array.from(options.values()).filter((i) => i.os.toLowerCase() === 'linux');
  }
  if (target === 6) {
    options = Array.isArray(options)
      ? options
      : Array.from(options.values()).filter((i) => i.os.toLowerCase() === 'mac');
  }
  return (
    <Picker
      {...props}
      style={{ width: '100%' }}
      placeholder="Please Select"
      onSearch={search}
      options={options}
    />
  );
  // return (
  //   <Transfer
  //     {...props}
  //     showSearch
  //     filterOption={(searchTerm, node) => {
  //       return (
  //         (node.title || node.filterLabel || node.label)
  //           .toLowerCase()
  //           .indexOf(searchTerm.toLowerCase()) >= 0
  //       );
  //     }}
  //     render={(i) => i.nodeRenderer}
  //     dataSource={Array.isArray(options) ? options : Array.from(options.values())}
  //     listStyle={{
  //       width: '45%',
  //       height: 350
  //     }}
  //   />
  // );
}

export const ComplianceBundle = {
  useConfigurationBundle: contextHook,
  Provider: Provider,
  Picker: ComplianceBundlePicker
};
