import generateId from '@/src/utils/id';
import api from '@api';

const END_POINT = `/patch/asset/configuration`;

const transformConfigAction = (i) => ({
  guid: generateId(),
  orderId: i.orderId,
  ...(i.commandType ? { commandType: i.commandType } : {}),
  ...(i.command ? { command: i.command } : {}),
  ...(i.scriptFile && i.scriptFile.refName
    ? {
        scriptFile: [
          {
            ref: i.scriptFile.refName,
            name: i.scriptFile.realName,
            uid: i.scriptFile.refName,
            url: `/api/patch/download/${i.scriptFile.refName}?mid=`,
            status: 'done'
          }
        ]
      }
    : {})
});

const transformConfigActionForServer = (i) => ({
  orderId: i.orderId,
  ...(i.commandType ? { commandType: i.commandType } : {}),
  ...(i.command ? { command: i.command } : {}),
  ...(i.scriptFile && i.scriptFile.length && (i.scriptFile[0].ref || i.scriptFile[0].response.ref)
    ? {
        scriptFile: {
          refName: i.scriptFile[0].ref || i.scriptFile[0].response.ref,
          realName: i.scriptFile[0].name || i.scriptFile[0].response.name
        }
      }
    : {})
});

const transform = (item) => ({
  id: item.id,
  name: item.name,
  isRemediation: item.isRemediation,
  displayName: item.displayName,
  description: item.description,
  os: item.os,
  arch: item.arch,
  configurationType: item.configurationType,
  selfServiceSupported: item.selfServiceSupported,
  configurationActions: (item.ConfigurationActions || []).map((i) => transformConfigAction(i)),
  createdBy: item.createdById,
  createdAt: item.createdTime,
  tags: item.tags
});

const transformForServer = async (item) => {
  return Promise.resolve({
    id: item.id,
    name: item.name,
    displayName: item.displayName,
    description: item.description,
    isRemediation: item.isRemediation,
    os: item.os,
    arch: item.arch,
    configurationType: item.configurationType,
    selfServiceSupported: item.selfServiceSupported,
    ConfigurationActions: (item.configurationActions || []).map((i, index) =>
      transformConfigActionForServer({ ...i, orderId: index + 1 })
    ),
    tags: item.tags
  });
};

const sortKeyMap = {
  createdAt: 'createdTime'
};

const searchableColumns = ['name', 'displayName', 'description'];

export function getRemediationConfigurationsApi(offset, size, sortFilter, configs = {}) {
  return api
    .post(`${END_POINT}/search`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              sortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      qualification: [
        {
          operator: 'Equals',
          column: 'isRemediation',
          value: 'true',
          condition: 'and'
        },
        ...(sortFilter.os
          ? [
              {
                operator: '=',
                column: 'os',
                value: sortFilter.os
              }
            ]
          : []),
        ...(sortFilter.ids
          ? [
              {
                operator: 'in',
                column: 'id',
                value: sortFilter.ids.join(',')
              }
            ]
          : []),
        // ...(sortFilter.searchTerm
        //   ? searchableColumns.map((c) => ({
        //       operator: 'Contains',
        //       column: c,
        //       value: sortFilter.searchTerm
        //     }))
        //   : []),
        ...(sortFilter.searchTerm
          ? [
              {
                operator: 'field_list_contains',
                columns: searchableColumns,
                value: sortFilter.searchTerm
              }
            ]
          : []),

        ...(configs?.selectedTags?.length
          ? configs.selectedTags.map((tag) => ({
              operator: 'in',
              column: 'tags',
              value: tag,
              condition: 'or'
            }))
          : [])
      ]
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: (data.result || []).map(transform)
      };
    });
}

export function getAllConfigurationsApi(offset, size, sortFilter, configs = {}) {
  return api
    .post(`${END_POINT}/search`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              sortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      qualification: [
        ...(sortFilter.isRemediation !== undefined
          ? [
              {
                operator: 'Equals',
                column: 'isRemediation',
                value: configs.isRemediation ? 'true' : 'false',
                condition: 'and'
              }
            ]
          : []),
        ...(sortFilter.os
          ? [
              {
                operator: '=',
                column: 'os',
                value: sortFilter.os
              }
            ]
          : []),
        ...(sortFilter.ids
          ? [
              {
                operator: 'in',
                column: 'id',
                value: sortFilter.ids.join(',')
              }
            ]
          : []),
        // ...(sortFilter.searchTerm
        //   ? searchableColumns.map((c) => ({
        //       operator: 'Contains',
        //       column: c,
        //       value: sortFilter.searchTerm
        //     }))
        //   : []),
        ...(sortFilter.searchTerm
          ? [
              {
                operator: 'field_list_contains',
                columns: searchableColumns,
                value: sortFilter.searchTerm
              }
            ]
          : []),

        ...(configs?.selectedTags?.length
          ? configs.selectedTags.map((tag) => ({
              operator: 'in',
              column: 'tags',
              value: tag,
              condition: 'or'
            }))
          : [])
      ]
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: (data.result || []).map(transform)
      };
    });
}

export function getConfigurationApi(id) {
  return api.get(`${END_POINT}/${id}`).then((result) => {
    return transform(result);
  });
}

export function updateConfigurationApi(item) {
  return transformForServer(item)
    .then((data) => {
      return api.put(`${END_POINT}/${item.id}`, data);
    })
    .then((data) => getConfigurationApi(data.result));
}

export function bulkDeleteConfigurationApi(ids) {
  return api.delete(`${END_POINT}`, {
    data: { ids }
  });
}

export function createConfigurationApi(item) {
  return transformForServer(item)
    .then((data) => {
      return api.post(`${END_POINT}`, data);
    })
    .then((data) => getConfigurationApi(data.result));
}

export function deleteConfigurationApi(item) {
  return api.delete(`${END_POINT}/${item.id}`);
}

export function getConfigurationsTagsApi(forTagSelction) {
  return api.get('/patch/asset/configuration/tags/filter').then((response) => {
    const result = response.result || {};
    const tagsArray = [];

    for (const tag in result) {
      if (Object.prototype.hasOwnProperty.call(result, tag)) {
        tagsArray.push({ tag: tag, tagCount: result[tag] });
      }
    }

    if (forTagSelction) {
      return tagsArray.map((tag) => ({
        label: tag.tag,
        value: tag.tag
      }));
    }
    return tagsArray;
  });
}

export function generateScriptApi(content, type, file_name) {
  return api
    .post(`/patch/generate`, {
      file_name: file_name || generateId(),
      file_content: content,
      script_type: type.toLowerCase()
    })
    .then((result) => {
      return {
        name: result.realName,
        ref: result.refName,
        uid: result.refName,
        url: `/api/patch/download/${result.refName}?mid=`,
        status: 'done'
      };
    });
}
