import { Row, Col, Button, Tag } from 'antd';
// import { Link } from 'react-router-dom';
// import { ReactComponent as Logo } from '@/src/assets/logo.svg';
// import PackageLogo from './PackageLogo';
import Icon from '@/src/components/Icon';
import SelectedItemPilles from '@components/SelectedItemPilles';

export function ConfigurationCard({ record, span = 6, showAssetLink, disabled, deleteItem, edit }) {
  return (
    <Col span={span} className="mb-4">
      <div className="w-full bg-seperator rounded-lg shadow-md flex  relative h-full justify-between">
        <Row gutter={0} className="w-full">
          <Col span={3} className="">
            <Button
              type="primary"
              size="large"
              className=" "
              disabled={false}
              style={{ minHeight: '100px', minWidth: '100px', border: '0px' }}>
              <Icon name={`platform_${record.os}`} style={{ fontSize: '3rem' }} />
            </Button>
          </Col>
          <Col span={16} className="py-4 ">
            <Row className="w-full h-full flex flex-col justify-between " gutter={0}>
              <h2 className="m-0 text-ellipsis w-full">{record.displayName}</h2>

              <p className="m-0 text-ellipsis w-full"> {record.description}</p>

              <div className="">
                <SelectedItemPilles
                  value={record.tags || []}
                  className="inline-block"
                  maxItems={3}
                />
              </div>
            </Row>
          </Col>

          <Col span={5} className="p-4 flex flex-col justify-between">
            <Row className="w-full flex justify-end" gutter={0}>
              <Button type="text" onClick={() => edit(record)}>
                <Icon name="edit" style={{ fontSize: '1.1rem' }} />
              </Button>

              <Button type="text" onClick={() => deleteItem(record)}>
                <Icon name="delete" style={{ fontSize: '1.1rem' }} />
              </Button>
            </Row>
            <div className="flex w-full justify-end mt-2">
              <div className="flex ">
                {record.isRemediation ? <Tag color="error">Remediation</Tag> : null}
                <Tag
                  color={'orange'}
                  className="inline-flex items-center justify-center"
                  style={{
                    textAlign: 'center',
                    textTransform: 'uppercase'
                  }}>
                  {record.arch}
                </Tag>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Col>
  );
}
