import Dayjs from 'dayjs';
import Omit from 'lodash/omit';
import api from '@api';
import generateId from '@/src/utils/id';
import { transformTimeline, transformTimelineForServer } from '../../dashboard/widget-api';
import {
  transformAssetScope,
  transformAssetScopeForServer
} from '@/src/components/pickers/AssetScopePicker';
import { transformQuery, transformQueryForServer } from '@/src/components/pickers/QueryDataPicker';

const END_POINT = `/analytics/report`;

export const transformSchedule = (item) => ({
  jobTime: Dayjs(item['job-time'], 'HH:mm'),
  jobDay: item['job-day'] - 1,
  expression: item['cron-expression']
});

export const transformScheduleForServer = (item) => ({
  'scheduler-type': item.scheduleType,
  ...(item.jobTime ? { 'job-time': item.jobTime.format('HH:mm') } : {}),
  ...(item.jobDay ? { 'job-day': item.jobDay + 1 } : {}),
  'cron-expression': item.expression
});

const transform = (item) => ({
  id: item.id,
  name: item.report_name,
  type: item.report_type,
  uuid: generateId(),
  description: item.description || '',
  timeline: transformTimeline(item.timeline_context || {}),
  ...transformAssetScope(item),
  ...transformQuery(item),
  isScheduled: +item.report_schedule_status === 1 ? true : false,
  ...(+item.report_schedule_status === 1
    ? {
        scheduleType: (item.report_schedule || {})['scheduler-type'],
        scheduleInfo: transformSchedule(item.report_schedule || {}),
        deliveryType: item.report_delivery,
        exportAs: item.report_export_type,
        recipients: item.report_recipients || []
      }
    : {}),
  createdAt: item.created_time,
  createdBy: item.created_by
});

const transformForServer = async (item) => {
  return Promise.resolve({
    report_name: item.name,
    report_type: item.type,
    description: item.description,
    ...transformQueryForServer(item.query_context),
    timeline_context: item.timeline ? transformTimelineForServer(item.timeline) : {},
    report_schedule_status: item.isScheduled ? 1 : 0,
    ...(item.isScheduled
      ? {
          report_schedule: transformScheduleForServer({
            ...(item.scheduleInfo || {}),
            scheduleType: item.scheduleType
          }),
          report_delivery: item.deliveryType,
          report_export_type: item.exportAs,
          report_recipients: item.recipients || []
        }
      : {
          report_schedule: null,
          report_delivery: null,
          report_export_type: null,
          report_recipients: null
        }),
    ...transformAssetScopeForServer(item)
  });
};

const sortKeyMap = {
  name: 'report_name',
  type: 'report_type',
  assetFilter: 'scope',
  createdBy: 'created_by'
};

const searchableColumns = ['report_name', 'description', 'report_type'];

export function getAllReportsApi(offset, size, sortFilter) {
  return api
    .post(`${END_POINT}/search`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              sortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      qualification: sortFilter.searchTerm
        ? searchableColumns.map((c) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm
          }))
        : []
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: data.result.map(transform)
      };
    });
}

export function getReportApi(id) {
  return api.get(`${END_POINT}/${id}`).then(({ result }) => transform(result));
}

export function updateReportApi(item) {
  return transformForServer(item)
    .then((data) => {
      return api.put(`${END_POINT}/${item.id}`, data);
    })
    .then((data) => getReportApi(data.result));
}

export function createReportApi(item) {
  return transformForServer(item)
    .then((data) => {
      return api.post(`${END_POINT}`, data);
    })
    .then((data) => getReportApi(data.result));
}

export function downloadReportApi(item) {
  return api.get(`/analytics/report/export/pdf/${item.id}/${item.uuid}`);
}

export function deleteReportApi(item) {
  return api.delete(`${END_POINT}/${item.id}`);
}

export function getTableSchemasApi() {
  return api.get(`/analytics/report/schema/json`).then(({ result }) => {
    return Object.keys(result).map((key) => ({
      tableName: result[key].tableName,
      tableOptions: Omit(result[key], ['columns', 'tableName']),
      tableDisplayName: key,
      columns: result[key].columns
    }));
  });
}
