import { Button } from 'antd';
import { useState } from 'react';
import FormModal from './FormModal';
import Icon from '../Icon';

export default function AiScriptGenerator({ type, onChange, platform, script_type, children }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button className="gradient-btn" type="primary" onClick={() => setIsModalOpen(true)}>
        {children || (
          <>
            <Icon name="ai" className="text-lg" />
            Generate {type === 'command' ? 'Command' : 'Script'}
          </>
        )}
      </Button>
      {isModalOpen ? (
        <FormModal
          platform={platform}
          script_type={script_type}
          onClose={() => setIsModalOpen(false)}
          onChange={onChange}
          type={type}
        />
      ) : null}
    </>
  );
}
