import {
  transformAssetScope,
  transformAssetScopeForServer
} from '@/src/components/pickers/AssetScopePicker';
import { transformPackageIcon } from '@/src/helpers/package-icon';
import api from '@api';
import {
  transformPatchDeployementForClient,
  transformPatchDeployementForServer
} from '@modules/patch/api/patch-deployement';
import {
  transformAutoPatchDeployementTestForClient,
  transformAutoPatchDeployementTestForServer
} from '@modules/patch/api/auto-patch-deployment-test';

const PATCH_END_POINT = `/patch/deployment`;

export const DEPLOYMENT_TYPE = {
  PACKAGE: 'package',
  CONFIGURATION: 'configuration',
  PATCH: 'Patch',
  AUTO_PATCH_DEPLOYMENT_TEST: 'auto-patch-deployment-test',
  AUTO_PATCH_DEPLOYMENT: 'auto-patch-deployment'
};

export const EXCLUDED_TYPE = [
  DEPLOYMENT_TYPE.AUTO_PATCH_DEPLOYMENT_TEST,
  DEPLOYMENT_TYPE.AUTO_PATCH_DEPLOYMENT
];

function getEndPoint(category) {
  if (category === DEPLOYMENT_TYPE.AUTO_PATCH_DEPLOYMENT_TEST) {
    return '/patch/auto-patch-test';
  } else if (category === DEPLOYMENT_TYPE.AUTO_PATCH_DEPLOYMENT) {
    return '/patch/auto-patch-deploy';
  }
  return PATCH_END_POINT;
}

const transform = (item, category) => ({
  ...(item.refModel ? transformCommanKeysForClient(item) : {}),
  ...(item.refModel === DEPLOYMENT_TYPE.PATCH ? transformPatchDeployementForClient(item) : {}),
  ...([DEPLOYMENT_TYPE.AUTO_PATCH_DEPLOYMENT_TEST, DEPLOYMENT_TYPE.AUTO_PATCH_DEPLOYMENT].includes(
    category
  )
    ? transformAutoPatchDeployementTestForClient(item)
    : {})
});

const transformForServer = async (item) => {
  return Promise.resolve({
    ...(!EXCLUDED_TYPE.includes(item.deploymentCategory) ? transformCommanKeysForServer(item) : {}),
    ...(item.deploymentCategory === DEPLOYMENT_TYPE.PATCH
      ? transformPatchDeployementForServer(item)
      : {}),
    ...([
      DEPLOYMENT_TYPE.AUTO_PATCH_DEPLOYMENT_TEST,
      DEPLOYMENT_TYPE.AUTO_PATCH_DEPLOYMENT
    ].includes(item.deploymentCategory)
      ? transformAutoPatchDeployementTestForServer(item)
      : {})
  });
};

function transformCommanKeysForClient(item) {
  return {
    id: item.id,
    name: item.name,
    deploymentCategory: item.refModel,
    displayName: item.displayName,
    description: item.description,
    deploymentType: item.deploymentType,
    deploymentStage: item.deploymentStage,
    packages: item.refModel === 'compliances' ? item.refIds[0] : item.refIds,
    resourceType: item.isPkgSelectAsBundle ? 'bundle' : 'individual',
    completedTasks: item.completedTaskCount,
    pendingTasks: item.pendingTaskCount,
    totalTasks: item.totalTaskCount || 0,
    ...transformAssetScope(item),
    deploymentPolicyId: item.deploymentPolicyId,
    notifyEmailIds: item.notifyEmailIds,
    retryCount: item.retryCount,
    createdBy: item.createdById,
    createdAt: item.createdTime,
    isRecurringDeployment: item.isRecurringDeployment,
    successTaskCount: item.successTaskCount,
    failedTaskCount: item.failedTaskCount,
    pendingTaskCount: item.pendingTaskCount,
    totalTaskCount: item.totalTaskCount
  };
}

function transformCommanKeysForServer(item) {
  return {
    id: item.id,
    IsPkgSelectAsBundle: item.resourceType === 'bundle',
    refModel: item.deploymentCategory,
    displayName: item.displayName,
    description: item.description,
    deploymentType: item.deploymentType,
    deploymentStage: item.deploymentStage || 'initiated',
    refIds: Array.isArray(item.packages) ? item.packages : [item.packages],
    ...transformAssetScopeForServer(item, true),
    deploymentPolicyId: item.deploymentPolicyId,
    notifyEmailIds: item.notifyEmailIds,
    retryCount: item.retryCount
  };
}

const sortKeyMap = {
  createdAt: 'createdTime',
  name: 'name',
  displayName: 'displayName',
  successTaskCount: 'successTaskCount',
  failedTaskCount: 'failedTaskCount',
  pendingTaskCount: 'pendingTaskCount'
};

const searchableColumns = ['name', 'displayName', 'description'];

export function getAllDeploymentsApi(offset, size, sortFilter, { category }) {
  return api
    .post(`${getEndPoint(category)}/search`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              sortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      qualification: (category && !EXCLUDED_TYPE.includes(category)
        ? [
            {
              operator: 'Equals',
              column: 'refModel',
              value: category,
              condition: 'and'
            }
          ]
        : []
      ).concat(
        sortFilter.searchTerm
          ? searchableColumns.map((c) => ({
              operator: 'Contains',
              column: c,
              value: sortFilter.searchTerm,
              condition: 'or'
            }))
          : []
      )
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: (data.result || []).map((item) => transform(item, category))
      };
    });
}

export function getDeploymentApi(id, { category }) {
  return api.get(`${getEndPoint(category)}/${id}`).then((result) => {
    return transform(result, category);
  });
}

export function updateDeploymentApi(item, { category }) {
  return transformForServer(item)
    .then((data) => {
      return api.put(`${getEndPoint(category)}/${item.id}`, data);
    })
    .then((data) => getDeploymentApi(data.result, { category }));
}

export function createDeploymentApi(item, { category }) {
  return transformForServer(item)
    .then((data) => {
      return api.post(`${getEndPoint(category)}`, data);
    })
    .then((data) => getDeploymentApi(data.result, { category }));
}

export function deleteDeploymentApi(item, { category }) {
  return api.delete(`${getEndPoint(category)}/${item.id}`);
}

const transformTask = (task) => ({
  id: task.id,
  displayName:
    task.patchTitle !== ''
      ? task.patchTitle
      : task.configurationDisplayName !== ''
      ? task.configurationDisplayName
      : task.packageDisplayName !== ''
      ? task.packageDisplayName
      : '',
  taskStatus: task.status,
  result: atob(task.task_result),
  createdBy: task.createdBy,
  createdAt: task.createdTime,
  type: task.refModel,
  endpoint: task.agentId,
  iconFile: transformPackageIcon(task),
  updatedTime: task.updatedTime
});

const taskSearchableColumns = [
  'task_result',
  'configurationDisplayName',
  'patchTitle',
  'packageDisplayName'
];

export function getAllDeploymentTasktsApi(
  offset,
  size,
  sortFilter,
  { deploymentId, selectedStatus }
) {
  return api
    .post(`/patch/agent/task-details/search`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              sortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      qualification: [
        {
          operator: 'Equals',
          column: 'deploymentId',
          value: deploymentId.toString(),
          condition: 'and'
        }
      ].concat(
        sortFilter.searchTerm
          ? taskSearchableColumns.map((c) => ({
              operator: 'Contains',
              column: c,
              value: sortFilter.searchTerm,
              condition: 'or'
            }))
          : [],
        selectedStatus && selectedStatus !== 'all'
          ? [{ operator: 'Contains', column: 'status', value: selectedStatus, condition: 'or' }]
          : []
      )
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: (data.result || []).map(transformTask)
      };
    });
}
