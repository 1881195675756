import { transformPackageIcon } from '@/src/helpers/package-icon';
import api from '@api';

const END_POINT = `/patch/bundle`;

const transform = (item) => ({
  id: item.id,
  name: item.name,
  description: item.description,
  os: item.os,
  type: item.refModel,
  referenceIds: item.referenceIds,
  createdBy: item.createdById,
  createdAt: item.createdTime,
  complianceType: item.complianceType,
  iconFile: transformPackageIcon(item)
});

const transformForServer = async (item) => {
  return Promise.resolve({
    id: item.id,
    refModel: item.type,
    name: item.name,
    description: item.description,
    referenceIds: item.referenceIds,
    complianceType: item.complianceType,
    iconFile:
      item.iconFile && item.iconFile.length && (item.iconFile[0].ref || item.iconFile[0].response)
        ? {
            refName: item.iconFile[0].ref || item.iconFile[0].response.ref,
            realName: item.iconFile[0].name || item.iconFile[0].response.name
          }
        : null,
    os: item.os
  });
};

const sortKeyMap = {
  createdAt: 'createdTime'
};

const searchableColumns = ['name', 'description'];

export function getAllBundlesApi(offset, size, sortFilter, { category }) {
  return api
    .post(`${END_POINT}/search`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              sortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      qualification: (category
        ? [
            {
              operator: 'Equals',
              column: 'refModel',
              value: category
            }
          ]
        : []
      ).concat(
        sortFilter.searchTerm
          ? searchableColumns.map((c) => ({
              operator: 'Contains',
              column: c,
              value: sortFilter.searchTerm
            }))
          : []
      )
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: (data.result || []).map(transform)
      };
    });
}

export function getBundleApi(id) {
  return api.get(`${END_POINT}/${id}`).then((result) => {
    return transform(result);
  });
}

export function updateBundleApi(item) {
  return transformForServer(item)
    .then((data) => {
      return api.put(`${END_POINT}/${item.id}`, data);
    })
    .then((data) => getBundleApi(data.result));
}

export function bulkDeleteBundleApi(ids) {
  return api.delete(`${END_POINT}`, {
    data: { ids }
  });
}

export function createBundleApi(item) {
  return transformForServer(item)
    .then((data) => {
      return api.post(`${END_POINT}`, data);
    })
    .then((data) => getBundleApi(data.result));
}

export function deleteBundleApi(item) {
  return api.delete(`${END_POINT}/${item.id}`);
}
